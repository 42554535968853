import React, {useContext, useEffect} from "react"
import Cookies from "js-cookie"
import AuthContext from "../Context/AuthContext"
import CartContext from "../Context/CartContext"
import classNames from "classnames";

export default () => {
  const {updateSession} = useContext(AuthContext)
  const {updateCartInfo} = useContext(CartContext)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem("personal")
      window.localStorage.clear()
    }
    Cookies.remove(process.env.STORE_SESSION_NAME, {domain: process.env.STORE_SESSION_DOMAIN})
    updateSession()
    updateCartInfo()
  }, [updateSession])

  return (
    <div className={classNames("container", "bg-white", "inner")}>
      <p style={{
        padding: "30px 0",
      }}>ログアウトしました</p>
    </div>
  )
}